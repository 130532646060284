import * as React from "react";
import Layout from "../../layouts";
import Button from "@partials/button";
import ImageBlock from "@partials/image-block/image-block";
import "./_gallery_styles.scss";
import QuoteSection from "@partials/quote-section/quote-section";
import { quotes, positions } from "../../../pageData";

import AbbieZabar_StillLife from "@images/galleries/series-II/AbbieZabar_StillLife.png";
import Agnes_Denes_Isometric from "@images/galleries/series-II/AgnesDenes_IsometricSystemsInIsotropicSpace.png";
import AlanGussow_Well from "@images/galleries/series-II/AlanGussow_Well-rottedManure_Steaming.png";
import AlexKatz_AlexKatz from "@images/galleries/series-II/AlexKatz_AlexKatz.png";
import AlexanderHollweg_Stonewall from "@images/galleries/series-II/AlexanderHollweg_Stonewall.png";
import BarkleyLHendricks__Me from "@images/galleries/series-II/BarkleyLHendricks__Me_(self-portrait).png";
import BethVanHoesen from "@images/galleries/series-II/BethVanHoesen_RatRat.png";
import BetyeSaar_AuntSallyHoodooTheParthenon from "@images/galleries/series-II/BetyeSaar_AuntSallyHoodooTheParthenon.png";
import BillSullivanSwan from "@images/galleries/series-II/BillSullivanSwan.png";
import CarlAnthony_SweetDream from "@images/galleries/series-II/CarlAnthony_SweetDream.png";
import CarlWall_LipService from "@images/galleries/series-II/CarlWald_LipService.png";
import CecileGray_BazelonDoors from "@images/galleries/series-II/CecileGray_BazelonDoors.png";
import ChrisvonWangenheim_RegineandChristine from "@images/galleries/series-II/ChrisvonWangenheim_RegineAndChristine.png";
import CostantinoNivola_ImissyouHeather from "@images/galleries/series-II/CostantinoNivola_IMissYouHeather.png";
import DavidHockney_SwimmingPoolFireIsland from "@images/galleries/series-II/DavidHockney_SwimmingPoolFireIsland.png";
import DickHiggins_snowflake_onturningforty from "@images/galleries/series-II/DickHiggins_Snowflake_OnTurningForty.png";
import DuaneMichals_HomagetoPuvisdeChavannes from "@images/galleries/series-II/DuaneMichals_HomageToPuvisDeChavannes.png";
import EdwardKoren_TheConferenceon from "@images/galleries/series-II/EdwardKoren_TheConferenceOnValuesTakesABreak.png";
import FannyBrennan_Birthday from "@images/galleries/series-II/FannyBrennan_Birthday.png";
import FrancesButlerBecause_words from "@images/galleries/series-II/FrancesButlerBecause_words.png";
import FranzCarlNicolay_NoNukesEverlastingAmen from "@images/galleries/series-II/FranzCarlNicolay_NoNukesEverlastingAmen.png";
import FredWMcDarrah_ElectricChairGreenhavenCorrectional from "@images/galleries/series-II/FredWMcDarrah_ElectricChairGreenhavenCorrectional.png";
import GeorgeGriffin_24Phases from "@images/galleries/series-II/GeorgeGriffin_24Phases.png";
import HughKepets_RailI from "@images/galleries/series-II/HughKepets_RailI.png";
import JaneFreilicher_Peony from "@images/galleries/series-II/JaneFreilicher_Peony.png";
import JohnFurnival_AdamFirstWordstoEve from "@images/galleries/series-II/JohnFurnival_AdamFirstWordstoEve.png";
import JohnMJohansen_Celebration from "@images/galleries/series-II/JohnMJohansen_Celebration.png";
import JonathanWilliams_PoetSilhouette from "@images/galleries/series-II/JonathanWilliams_PoetSilhouette.png";
import JosephGoldyne_Couple from "@images/galleries/series-II/JosephGoldyne_Couple.png";
import JosephSzilva_WishYouWereHer from "@images/galleries/series-II/JosephSzilva_WishYouWereHer.png";
import KatharineNuckolsGilbert_FamilyFantasyChatham from "@images/galleries/series-II/KatharineNuckolsGilbert_FamilyFantasyChatham.png";
import LeahFeldon_Manyshallruntoandfro from "@images/galleries/series-II/LeahFeldon_Manyshallruntoandfro.png";
import LellaandMassimoVignelli_Heartless from "@images/galleries/series-II/LellaAndMassimoVignelli_Heartless.png";
import LellaandMassimoVignelli_STAR from "@images/galleries/series-II/LellaAndMassimoVignelli_STAR.png";
import LouisIKahn_PyramidsEgypt1951 from "@images/galleries/series-II/LouisIKahn_PyramidsEgypt1951.png";
import LyndaCaspe_TheAtalantaBuildingNewYorkCity from "@images/galleries/series-II/LyndaCaspe_TheAtalantaBuildingNewYorkCity.png";
import ManuelCasimiro_Oedipeexpliquantlenigme from "@images/galleries/series-II/ManuelCasimiro_Oedipeexpliquantlenigme.png";
import MarthaGraham_FromtheNotebooks from "@images/galleries/series-II/MarthaGraham_FromTheNotebooks.png";
import NeilBlaine_AnemonesRedTableclothandWindow from "@images/galleries/series-II/NeilBlaine_AnemonesRedTableclothandWindow.png";
import NormanColp_Steam_MyView from "@images/galleries/series-II/NormanColp_Steam_MyView.png";
import PatAdams_Remarks from "@images/galleries/series-II/PatAdams_Remarks.png";
import RaymondSaunders_XO from "@images/galleries/series-II/RaymondSaunders_XO.png";
import RobertCuneo_RevBobUniversalLifeChurchofPoetryArt from "@images/galleries/series-II/RobertCuneo_RevBobUniversalLifeChurchofPoetryArt.png";
import RobertMacMillan_SniffingGypsy from "@images/galleries/series-II/RobertMacMillan_SniffingGypsy.png";
import RobertMapplethorpe_Torso from "@images/galleries/series-II/RobertMapplethorpe_Torso.png";
import StephanieBrodyLederman_WaitinandWantin from "@images/galleries/series-II/StephanieBrodyLederman_WaitinandWantin.png";
import TomPrassis_AFaceinthecityEargoSum from "@images/galleries/series-II/TomPrassis_AFaceinthecityEargoSum.png";
import TomWolfe_GreatMomentsinBroadcastJournalism_thePreshowDryblow from "@images/galleries/series-II/TomWolfe_GreatMomentsinBroadcastJournalism_thePre-showDry-blow.png";
import TomZetterstromMapleTreeWesternMassachusetts from "@images/galleries/series-II/TomZetterstrom_MapleTreeWesternMassachusetts.png";
import WayneThiebaud_FiveCandyTreats from "@images/galleries/series-II/WayneThiebaud_FiveCandyTreats.png";
import WendellBarryStevens_DarkHorizon from "@images/galleries/series-II/WendellBarryStevens_DarkHorizon.png";
import WilliamCopley_GardenforBlackTears from "@images/galleries/series-II/WilliamCopley_GardenforBlackTears.png";

export default function SeriesTwo() {
    return (
        <Layout pageTitle="Series II">
            <section className="gallery-hero-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row c-mb-only-4">
                        <div className="col-lg-7">
                            <h1 className="c-mb-only-2">Artists’ Postcards SERIES II</h1>
                        </div>
                        <div className="col-lg-6">
                            <p className="c-mb-only-3">
                                Artists’ Postcards is a nonprofit venture founded in 1977 to bring something new to the American scene — high quality, general circulation postcards made from original works that artists created in 4 X 6 inch format expressed to be published as postcards.
                            </p>
                            <p className="c-mb-only-3">
                                Series II consists of fifty works in varied media, produced originally on more than ten different papers, by diverse printing processes. The artists are painters, sculptors, photographers, poets, cartoonists, writers, filmmakers, architects, and a dancer.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <p className="c-mb-only-3">
                                The original works for Series II were exhibited in 1978 at the Cooper - Hewitt Museum in New York City and traveled to museums throughout the United States in an exhibition developed by the Smithsonian Institution Traveling Exhibition Service.
                            </p>
                            <p className="c-mb-only-3">
                                Series II included an Artists’ Postcard Special Edition, a card by Michael Langenstein, which used flicker-photo technique and juxtaposes the Twin Towers of the World Trade Center with both the ancient Egyptian desert and the landscape of the moon. This card is today in the collection of the 9/11 Memorial Museum in New York.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Abbie Zabar"
                                image={AbbieZabar_StillLife}
                                imageX2={AbbieZabar_StillLife}
                                caption="Still Life"
                                alt="Still Life"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Agnes Denes"
                                image={Agnes_Denes_Isometric}
                                imageX2={Agnes_Denes_Isometric}
                                caption="Isometric Systems in Isotropic Space"
                                alt="Isometric Systems in Isotropic Space"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Alan Gussow"
                                image={AlanGussow_Well}
                                imageX2={AlanGussow_Well}
                                caption="Well-rotted Manure Steaming"
                                alt="Well-rotted Manure Steaming"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-6">
                            <ImageBlock
                                author="Alex Katz"
                                image={AlexKatz_AlexKatz}
                                imageX2={AlexKatz_AlexKatz}
                                caption="Alex Katz"
                                alt=""
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Alexander Hollweg"
                                image={AlexanderHollweg_Stonewall}
                                imageX2={AlexanderHollweg_Stonewall}
                                caption="Stonewall"
                                alt="Stonewall"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-6">
                            <ImageBlock
                                author="Barkley L. Hendricks"
                                image={BarkleyLHendricks__Me}
                                imageX2={BarkleyLHendricks__Me}
                                caption="Me (self-portrait)"
                                alt="Author self-portrait"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Beth Van  Hoesen"
                                image={BethVanHoesen}
                                imageX2={BethVanHoesen}
                                caption="Rat"
                                alt="Rat"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Betye Saar"
                                image={BetyeSaar_AuntSallyHoodooTheParthenon}
                                imageX2={BetyeSaar_AuntSallyHoodooTheParthenon}
                                caption="Aunt Sally Hoodoo´s The Parthenon"
                                alt="Aunt Sally Hoodoo´s The Parthenon"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteV} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Bill Sullivan"
                                image={BillSullivanSwan}
                                imageX2={BillSullivanSwan}
                                caption="Swan"
                                alt="Swan"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Carl Anthony"
                                image={CarlAnthony_SweetDream}
                                imageX2={CarlAnthony_SweetDream}
                                caption="Sweet Dream"
                                alt="Sweet Dream"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Carl Wald"
                                image={CarlWall_LipService}
                                imageX2={CarlWall_LipService}
                                caption="Lip Service"
                                alt="Lip Service"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Cecile Gray"
                                image={CecileGray_BazelonDoors}
                                imageX2={CecileGray_BazelonDoors}
                                caption="Bazelon, Doors"
                                alt="Bazelon, Doors"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-1">
                            <ImageBlock
                                author="Chris von Wangenheim"
                                image={ChrisvonWangenheim_RegineandChristine}
                                imageX2={ChrisvonWangenheim_RegineandChristine}
                                caption="Regine and Christine"
                                alt="Regine and Christine"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-6">
                            <ImageBlock
                                author="Costantino Nivola"
                                image={CostantinoNivola_ImissyouHeather}
                                imageX2={CostantinoNivola_ImissyouHeather}
                                caption="I miss you Heather"
                                alt="I miss you Heather"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="David Hockney"
                                image={DavidHockney_SwimmingPoolFireIsland}
                                imageX2={DavidHockney_SwimmingPoolFireIsland}
                                caption="Swimming Pool, Fire Island"
                                alt="Swimming Pool, Fire Island"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-6">
                            <ImageBlock
                                author="Dick Higgins"
                                image={DickHiggins_snowflake_onturningforty}
                                imageX2={DickHiggins_snowflake_onturningforty}
                                caption="Snowflake on turning forty"
                                alt="Snowflake on turning forty"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteVI} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Duane Michals"
                                image={DuaneMichals_HomagetoPuvisdeChavannes}
                                imageX2={DuaneMichals_HomagetoPuvisdeChavannes}
                                caption="Homage to Puvis de Chavannes"
                                alt="Homage to Puvis de Chavannes"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Edward Koren"
                                image={EdwardKoren_TheConferenceon}
                                imageX2={EdwardKoren_TheConferenceon}
                                caption="The Conference on Values Takes a Break"
                                alt="The Conference on Values Takes a Break"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Fanny Brennan"
                                image={FannyBrennan_Birthday}
                                imageX2={FannyBrennan_Birthday}
                                caption="Birthday"
                                alt="Birthday"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Frances Butler"
                                image={FrancesButlerBecause_words}
                                imageX2={FrancesButlerBecause_words}
                                caption="Because it´s all gesture and nobody ever talked in words"
                                alt="Because it´s all gesture and nobody ever talked in words"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Franz Carl Nicolay"
                                image={FranzCarlNicolay_NoNukesEverlastingAmen}
                                imageX2={FranzCarlNicolay_NoNukesEverlastingAmen}
                                caption="No Nukes Everlasting, Amen"
                                alt="No Nukes Everlasting, Amen"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Fred W. McDarrah"
                                image={FredWMcDarrah_ElectricChairGreenhavenCorrectional}
                                imageX2={FredWMcDarrah_ElectricChairGreenhavenCorrectional}
                                caption="Electric Chair, Greenhaven Correctional"
                                alt="Electric Chair, Greenhaven Correctional"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="George Griffin"
                                image={GeorgeGriffin_24Phases}
                                imageX2={GeorgeGriffin_24Phases}
                                caption="24 Phases"
                                alt="24 Phases"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Hugh Kepets"
                                image={HughKepets_RailI}
                                imageX2={HughKepets_RailI}
                                caption="Rail I"
                                alt="Rail"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteVII} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Jane Freilicher"
                                image={JaneFreilicher_Peony}
                                imageX2={JaneFreilicher_Peony}
                                caption="Peony"
                                alt="Peony"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="John Furnival"
                                image={JohnFurnival_AdamFirstWordstoEve}
                                imageX2={JohnFurnival_AdamFirstWordstoEve}
                                caption="Adam´s First Words to Eve"
                                alt="Adam´s First Words to Eve"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-1">
                            <ImageBlock
                                author="Jonathan Williams"
                                image={JonathanWilliams_PoetSilhouette}
                                imageX2={JonathanWilliams_PoetSilhouette}
                                caption="Poet´s Silhouette"
                                alt="Poet´s Silhouette"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="John M. Johansen"
                                image={JohnMJohansen_Celebration}
                                imageX2={JohnMJohansen_Celebration}
                                caption="Celebration"
                                alt="Celebration"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Joseph Goldyne"
                                image={JosephGoldyne_Couple}
                                imageX2={JosephGoldyne_Couple}
                                caption="Couple"
                                alt="Couple"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-6">
                            <ImageBlock
                                author="Joseph Szilva"
                                image={JosephSzilva_WishYouWereHer}
                                imageX2={JosephSzilva_WishYouWereHer}
                                caption="Wish You Were Her"
                                alt="Wish You Were Her"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Katharine Nuckols Gilbert"
                                image={KatharineNuckolsGilbert_FamilyFantasyChatham}
                                imageX2={KatharineNuckolsGilbert_FamilyFantasyChatham}
                                caption="Family Fantasy, Chatham"
                                alt="Family Fantasy, Chatham"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Leah Feldon"
                                image={LeahFeldon_Manyshallruntoandfro}
                                imageX2={LeahFeldon_Manyshallruntoandfro}
                                caption="... many shall run to and fro..."
                                alt="Two running people with dog"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteVIII} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Lella and Massimo Vignelli"
                                image={LellaandMassimoVignelli_Heartless}
                                imageX2={LellaandMassimoVignelli_Heartless}
                                caption="Heartless"
                                alt="Heartless"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Lella and Massimo Vignelli"
                                image={LellaandMassimoVignelli_STAR}
                                imageX2={LellaandMassimoVignelli_STAR}
                                caption="Star"
                                alt="Star in red green"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Louis I. Kahn"
                                image={LouisIKahn_PyramidsEgypt1951}
                                imageX2={LouisIKahn_PyramidsEgypt1951}
                                caption="Pyramids, Egypt, 1951"
                                alt="Pyramids, Egypt, 1951"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Lynda Caspe"
                                image={LyndaCaspe_TheAtalantaBuildingNewYorkCity}
                                imageX2={LyndaCaspe_TheAtalantaBuildingNewYorkCity}
                                caption="The Atalanta Building New York City"
                                alt="The Atalanta Building New York City"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Manuel Casimiro"
                                image={ManuelCasimiro_Oedipeexpliquantlenigme}
                                imageX2={ManuelCasimiro_Oedipeexpliquantlenigme}
                                caption="Oedipe expliquant l´enigme"
                                alt=""
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Martha Graham"
                                image={MarthaGraham_FromtheNotebooks}
                                imageX2={MarthaGraham_FromtheNotebooks}
                                caption="From the Notebooks"
                                alt="Dancing woman in black dress"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Neil Blaine"
                                image={NeilBlaine_AnemonesRedTableclothandWindow}
                                imageX2={NeilBlaine_AnemonesRedTableclothandWindow}
                                caption="Anemones, Red Tablecloth, and Window"
                                alt="Anemones, Red Tablecloth, and Window"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Norman B. Colp"
                                image={NormanColp_Steam_MyView}
                                imageX2={NormanColp_Steam_MyView}
                                caption="My View from P.S.1 (a flip-book)"
                                alt="Images of chimneys"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteIX} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Pat Adams"
                                image={PatAdams_Remarks}
                                imageX2={PatAdams_Remarks}
                                caption="Remarks"
                                alt="Remarks"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Raymond Saunders"
                                image={RaymondSaunders_XO}
                                imageX2={RaymondSaunders_XO}
                                caption="XO"
                                alt="Abstraction"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Robert J. Cuneo"
                                image={RobertCuneo_RevBobUniversalLifeChurchofPoetryArt}
                                imageX2={RobertCuneo_RevBobUniversalLifeChurchofPoetryArt}
                                caption="Rev. Bob´s Universal Life Church of Poetry Art"
                                alt="Bob´s Universal Life Church of Poetry Art"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Robert MacMillan"
                                image={RobertMacMillan_SniffingGypsy}
                                imageX2={RobertMacMillan_SniffingGypsy}
                                caption="Sniffing Gypsy"
                                alt="Gybsy sniffing lion"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Robert Mapplethorpe"
                                image={RobertMapplethorpe_Torso}
                                imageX2={RobertMapplethorpe_Torso}
                                caption="Torso"
                                alt="Muscular torso"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Stephanie Brody Lederman"
                                image={StephanieBrodyLederman_WaitinandWantin}
                                imageX2={StephanieBrodyLederman_WaitinandWantin}
                                caption="Waitin´ and Wantin´"
                                alt="Waitin´ and Wantin´"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                author="Tom Prassis"
                                image={TomPrassis_AFaceinthecityEargoSum}
                                imageX2={TomPrassis_AFaceinthecityEargoSum}
                                caption="A Face in the city ( Ear-go Sum)"
                                alt="A Face in the city"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7">
                            <ImageBlock
                                author="Tom Wolfe"
                                image={TomWolfe_GreatMomentsinBroadcastJournalism_thePreshowDryblow}
                                imageX2={
                                    TomWolfe_GreatMomentsinBroadcastJournalism_thePreshowDryblow
                                }
                                caption="Great Moments in Broadcast Journalism the Pre-show Dry-blow"
                                alt="Man drying his hair"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryQuoteX} mt0/>
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <ImageBlock
                                imageCustomClassName="border border-2"
                                author="Tom Zetterstrom"
                                image={TomZetterstromMapleTreeWesternMassachusetts}
                                imageX2={TomZetterstromMapleTreeWesternMassachusetts}
                                caption="Maple Tree, Western Massachusetts"
                                alt="Maple Tree, Western Massachusetts"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="Wayne Thiebaud"
                                image={WayneThiebaud_FiveCandyTreats}
                                imageX2={WayneThiebaud_FiveCandyTreats}
                                caption="Five Candy Treats"
                                alt="Five candys"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <ImageBlock
                                author="Wendell Barry Stevens"
                                image={WendellBarryStevens_DarkHorizon}
                                imageX2={WendellBarryStevens_DarkHorizon}
                                caption="Dark Horizon"
                                alt="Dark Horizon"
                                signaturePosition={positions.BOTTOM_RIGHT}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 offset-lg-5">
                            <ImageBlock
                                author="William Copley"
                                image={WilliamCopley_GardenforBlackTears}
                                imageX2={WilliamCopley_GardenforBlackTears}
                                caption="Garden for Black Tears"
                                alt="Garden for Black Tears"
                                signaturePosition={positions.BOTTOM_LEFT}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="gallery-intro-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="text-primary fz-50 lh-1-3">Series I</h2>
                        </div>
                        <div className="col-lg-8">
                            <p className="c-mb-only-5 fz-24 lh-1-5 fw-light">
                                The initial edition of thirty-nine postcards was the first of its kind to be published in the U.S.A. Distinct from art postcards that are miniature versions of celebrated works, each Artists’ Postcard carries a new picture created especially for it by a living artist.
                            </p>
                            <Button link="/gallery/series-I" buttonText="Explore Series I" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="gallery-intro-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="text-primary fz-50 lh-1-4">Holiday Cards</h2>
                        </div>
                        <div className="col-lg-8">
                            <p className="c-mb-only-5 fz-24 lh-1-5 fw-light">
                                Based on the popularity of the Artists’ Postcards Series I and II editions, select artists from the earlier series created special cards for the 1979 holiday season.
                            </p>
                            <Button link="/gallery/holiday-cards" buttonText="Explore Holiday Cards" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
